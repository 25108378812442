import React from "react"
import { useState, useEffect } from "react";
import styled from "styled-components";

import Seo from "../components/util/Seo"
// import Header from "../components/homePage/Header";
import NavbarSimple from "../components/layout/NavbarSimple";
import Footer from "../components/layout/Footer";

//assetsgit rebase dev
import darkwingVideo from '../components/homePage/homePage_assets/darkwing.mp4'
import vidBannerHaplyLogo from '../components/homePage/homePage_assets/vidBannerHaplyLogo.png'
import inverseBackgroundImage from '../components/homePage/homePage_assets/inverseBackgroundImage.jpg'
import i3LOGO from '../components/homePage/homePage_assets/i3Logo.png'

import portable from '../components/homePage/homePage_assets/portable.png'
import precise from '../components/homePage/homePage_assets/Precise2-white.png'
import versatile from '../components/homePage/homePage_assets/Tools.png'
import adaptable from '../components/homePage/homePage_assets/Adaptable+-+prod.png'
import devFriendly from '../components/homePage/homePage_assets/Developer.png'
import I3Img from '../components/homePage/homePage_assets/i3.jpg'

import controllers from '../components/homePage/homePage_assets/HaplyColors02.png'
import toolsAndAccessories from '../components/homePage/homePage_assets/tweezer_inverse3.jpg'
import store from '../components/homePage/homePage_assets/HaplyLaptop.png'

import NividiaLogo from '../components/homePage/homePage_assets/NividiaLogo.png'
import EpicGamesLogo from '../components/homePage/homePage_assets/EpicGamesLogo.png'
import MedteqLogo from '../components/homePage/homePage_assets/MedteqLogo.png'
import VirteasyLogo from '../components/homePage/homePage_assets/Virteasy.png'
import IMSTKLogo from '../components/homePage/homePage_assets/IMSTK.png'
import FundamentalLogo from '../components/homePage/homePage_assets/Fundamental.png'
import ToiaLogo from '../components/homePage/homePage_assets/Toia.png'
import AutodeskLogo from '../components/homePage/homePage_assets/Autodesk.png'

import HapInAction from '../components/homePage/homePage_assets/Haptics_in_Action.mp4'
import IdeaToProduction from '../components/homePage/homePage_assets/Idea_to_Production.mp4'
import ThenAndNow from '../components/homePage/homePage_assets/Then_And_Now.mp4'
import { useI18next } from "gatsby-plugin-react-i18next";



const Home = () => {

  const { language } = useI18next()

  const [carouselMouseDown, setCarouselMouseDown] = useState(false)
  const [mouseInitPosition, setMouseInitPosition] = useState(0)

  const [carouselScrollVal, setCarouselScrollVal] = useState(0)

  const handleMouseDown = (e) => {
    if(e.target.id !== 'carousel'){
      return
    }
    // console.log(e.clientX)
    setMouseInitPosition(e.clientX)
    setCarouselMouseDown(true)
  }

  const handleScroll = (e) => {
    if(carouselMouseDown){
      let change = mouseInitPosition - e.clientX
      // console.log(change)
      e.target.scrollLeft = carouselScrollVal + change
    }
  }

  const handleMouseUp = (e) => {
    if(e.target.id !== 'carousel'){
      return
    }
    setCarouselMouseDown(false);
    setCarouselScrollVal(e.target.scrollLeft)
  }

  let scrollP = 0

  const handleClick = (e) => {
    // console.log(e, 'hey')
  }

  useEffect(()=> {
    document.getElementById('carousel').addEventListener('click', handleClick)
    return document.getElementById('carousel').removeEventListener('click', handleClick)

  },[])




  let Texts = {
  "BannerVideoSection": {
    "button": {
      "en": "Shop",
      "fr": "Magasin"
    }
  },
   "Inverse3Section": {
    "H2": {
      "en": "Powering a new generation of immersive digital experience",
      "fr": "Une nouvelle génération d'expériences numériques immersives"
    },
    "H3": {
      "en": "A new kind of force-feedback controller, reimagined for unmatched versatility",
      "fr": "Un nouveau type de contrôleur à retour de force, réimaginé pour une polyvalence inégalée"
    },
    "P": {
      "en": "The inverse3 is the only haptic interface designed to be compact, lightweight, reconfigurable, and completely portable. Wireless tool tracking enables you to move freely through virtual environments, while our quick tool change mechanism allows you to connect VR controllers or replica instruments and tools to leverage the inverse3's unmatched power, precision and force feedback.",
      "fr": "L'inverse3 est la seule interface haptique conçue pour être compacte, légère, reconfigurable et totalement portable. Le suivi d'outil sans fil vous permet de vous déplacer librement dans les environnements virtuels, tandis que notre mécanisme de changement rapide d'outil vous permet de connecter des contrôleurs VR ou des répliques d'instruments et d'outils pour tirer parti de la puissance, de la précision et du retour de force inégalés de l'inverse3."
    },
    "button": {
      "en": "Learn More",
      "fr": "Plus"
    }
   },
   "ValuePropsSection": {
    "ValueProp1": {
      "H3": {
        "en": "portable",
        "fr": "Plus"
      },
      "P": {
        "en": "lightweight design and small form-factor provides unmatched portability",
        "fr": "le design léger et le petit facteur de forme offrent une portabilité inégalée"
      },
    },
    "ValueProp2": {
      "H3": {
        "en": "precise",
        "fr": "précis"
      },
      "P": {
        "en": "advanced engineering employed to operate with sub-millimeter precision",
        "fr": "l'ingénierie de pointe utilisée pour fonctionner avec une précision submillimétrique"
      }
    },
    "ValueProp3": {
      "H3": {
        "en": "versatile",
        "fr": "polyvalent"
      },
      "P": {
        "en": "patent pending quick tool change mechanism offers unmatched tool versatility",
        "fr": "le mécanisme de changement rapide d'outil en instance de brevet offre une polyvalence d'outil inégalée"
      },
    },
    "ValueProp4": {
      "H3": {
        "en": "adaptable",
        "fr": "adaptable"
      },
      "P": {
        "en": "Supports various training scenarios via countless mounting configurations",
        "fr": "Permet de réaliser divers scénarios de formation grâce à d'innombrables configurations de montage"
      },
    },
    "ValueProp5": {
      "H3": {
        "en": "dev-friendly",
        "fr": "favorable aux développeurs"
      },
      "P": {
        "en": "Designed to be developer-friendly and integrate with your desired physics engine",
        "fr": "Conçu pour être convivial pour les développeurs et s'intégrer au moteur physique de votre choix"
      },
    },
   },
   "QuotesSection" : {
    "H2": {
        "en": "What industry leaders have to say",
        "fr": "Ce que les leaders du secteur ont à dire"
      },
    "Quote1":{
      "H3": {
        "en": "Jensen Huang, CEO, Nividia",
        "fr": "Jensen Huang, PDG, Nividia"
      },
      "P": {
        "en": `"One of the 12 companies revolutionizing medical robotics"`,
        "fr": `"L'une des 12 entreprises qui révolutionnent la robotique médicale"`
      },
    },
    "Quote2":{
      "H3": {
        "en": "Sébastien Lozé, Director of Business for Simulation, Epic Games",
        "fr": "Sébastien Lozé, directeur des affaires pour la simulation, Epic Games"
      },
      "P": {
        "en": `"Bringing training to the next level of immersion"`,
        "fr": `"La formation au niveau d'immersion le plus élevé".`
      },
    },
    "Quote3":{
      "H3": {
        "en": "MEDTEQ+",
        "fr": "MEDTEQ+"
      },
      "P": {
        "en": `"Top 20 healthtech startups to watch in Canada"`,
        "fr": `"Les 20 meilleures startups du secteur de la santé à suivre au Canada"`
      },
    },
   },
   "PartnershipsSection": {
    "P": {
        "en": "For more insight on the types of companies and institutions we work with, check out our",
        "fr": "Pour en savoir plus sur les types d'entreprises et d'institutions avec lesquelles nous travaillons, consultez notre rubrique",
        "span": {
          "en": " use cases.",
          "fr": " cas d'utilisation."
        }
      },
   },
   "OurProductTitleSection": {
      "en": "Our product",
      "fr": "Notre produit"
    },
    "CarouselSection": {
      "CarouselCardContent1": {
        "H3": {
          "en": "Controllers",
          "fr": "Contrôleurs"
        },
        "P": {
          "en": "The next generation of physical simulation controllers",
          "fr": "La nouvelle génération de contrôleurs de simulation physique"
        },
        "button": {
          "en": "Shop",
          "fr": "Magasin"
        }
      },
      "CarouselCardContent2": {
        "H3": {
          "en": "Tools & Accessories",
          "fr": "Outils et accessoires"
        },
        "P": {
          "en": "Powered by Haply technology",
          "fr": "La technologie Haply au service de l'innovation"
        },
        "button": {
          "en": "Shop",
          "fr": "Magasin"
        },
      },
      "CarouselCardContent3": {
        "H3": {
          "en": "Store",
          "fr": "Boutique"
        },
        "P": {
          "en": "Be an early supporter of our mission and vision",
          "fr": "Soutenir dès le début notre mission et notre vision"
        },
        "button": {
          "en": "Shop",
          "fr": "Magasin"
        },
      },

    }, "VideoGallery": {
      "H2": {
        "en": "Learn more",
        "fr": "Plus"
      },
      "VideoCard1": {
        "warning": {
          "en": "Your browser does not support the video tag.",
          "fr": "Votre navigateur ne prend pas en charge la balise vidéo.",
        },
        "H2": {
          "en": "HAPTICS IN ACTION",
          "fr": "L'HAPTIQUE EN ACTION"
        },
        "P": {
          "en": "Watch how the inverse3 controller performs using 3D simulation softwares, providing responsive force-feedback to different textures and materials.",
          "fr": "Observez les performances du contrôleur inverse3 à l'aide de logiciels de simulation 3D, en fournissant un retour de force réactif sur différentes textures et matériaux.",
        }
  
      }, "VideoCard2": {
        "warning": {
          "en": "Your browser does not support the video tag.",
          "fr": "Votre navigateur ne prend pas en charge la balise vidéo.",
        },
        "H2": {
          "en": "FROM IDEA TO PRODUCTION",
          "fr": "DE L'IDÉE À LA PRODUCTION"
        },
        "P": {
          "en": "Watch the process of developing a new handle for our inverse3 controller. From design to 3D printing, assembly, and testing.",
          "fr": "Regardez le processus de développement d'une nouvelle poignée pour notre contrôleur inverse3. De la conception à l'impression 3D, en passant par l'assemblage et les tests.",
        }
  
      }, "VideoCard3": {
        "warning": {
          "en": "Your browser does not support the video tag.",
          "fr": "Votre navigateur ne prend pas en charge la balise vidéo.",
        },
        "H2": {
          "en": "INVERSE3: THEN AND NOW",
          "fr": "INVERSE3 : AUJOURD'HUI ET AUJOURD'HUI"
        },
        "P": {
          "en": "Watch the iterative evolution of our first product, the state-of-the-art inverse3.",
          "fr": "Observez l'évolution itérative de notre premier produit, l'inverse3, à la pointe de la technologie.",
        }
  
      },
    },
    "ContactFormSection": {
      "H3": {
        "en": "Stay in the loop",
        "fr": "Restez dans le courant",
      },
      "P": {
        "en": "Get exclusive access to early releases, promotions, product updates, and company news.",
        "fr": "Obtenez un accès exclusif aux premières versions, aux promotions, aux mises à jour de produits et aux nouvelles de l'entreprise.",
      },
      "Inputs": {
        "I1": {
          "en": "First Name",
          "fr": "Prénom"
        },
        "I2": {
          "en": "Last Name",
          "fr": "Nom de famille"
        },
        "I3": {
          "en": "Email Address",
          "fr": "Adresse électronique"
        },
      },
      "button": {
        "en": "Submit",
        "fr": "Soumettre"
      }
    }


  }

  
  return (
    <HomeWrapper>
      <NavbarSimple></NavbarSimple>
      <BannerVideoSection>
        <VidSecContentContainer>
          <VidSecMiddleFloatContainer>
            <HaplyLogoPng src={vidBannerHaplyLogo} alt={'haply-logo'}/>
            <button className='button-style-1'>{Texts.BannerVideoSection.button[language]}</button>
          </VidSecMiddleFloatContainer>
        </VidSecContentContainer>
        <VideoBackground autoPlay muted loop><source src={darkwingVideo}></source></VideoBackground>

      </BannerVideoSection>
      {/* <Header/> */}

      <Inverse3Section >
        <Inverse3BGImg src={inverseBackgroundImage} alt=''></Inverse3BGImg>
        <BodyContentContainer>
          <Inverse3SecContentContainer>
            <Inverse3SecMiddleFloatContainer>
              <I3LogoImg src={i3LOGO} alt='i3logo'/>
              <H2>{Texts.Inverse3Section.H2[language]}</H2>
              <H3>{Texts.Inverse3Section.H3[language]}</H3>
              <P>{Texts.Inverse3Section.P[language]}</P>
              <button className='button-style-1'>{Texts.Inverse3Section.button[language]}</button>
            </Inverse3SecMiddleFloatContainer>
          </Inverse3SecContentContainer>
        </BodyContentContainer>
      </Inverse3Section>

      <ValuePropsSection> 
        <BodyContentContainer>
          <ValuePropRow>
            <ValueProp>
              <ValuePropImage src={portable} alt={'Portable Icon'}></ValuePropImage>
              <H3>{Texts.ValuePropsSection.ValueProp1.H3[language]}</H3>
              <P>{Texts.ValuePropsSection.ValueProp1.P[language]}</P>
            </ValueProp>
            <ValueProp>
              <ValuePropImage src={precise} alt={'Precise Icon'}></ValuePropImage>
              <H3>{Texts.ValuePropsSection.ValueProp2.H3[language]}</H3>
              <P>{Texts.ValuePropsSection.ValueProp2.P[language]}</P>
            </ValueProp>
            <ValueProp>
              <ValuePropImage src={versatile} alt={'Versatile Icon'}></ValuePropImage>
              <H3>{Texts.ValuePropsSection.ValueProp3.H3[language]}</H3>
              <P>{Texts.ValuePropsSection.ValueProp3.P[language]}</P>
            </ValueProp>
            <ValueProp>
              <ValuePropImage src={adaptable} alt={'Adaptable Icon'}></ValuePropImage>
              <H3>{Texts.ValuePropsSection.ValueProp4.H3[language]}</H3>
              <P>{Texts.ValuePropsSection.ValueProp4.P[language]}</P>
            </ValueProp>
            <ValueProp>
              <ValuePropImage src={devFriendly} alt={'Dev-Friendly Icon'}></ValuePropImage>
              <H3>{Texts.ValuePropsSection.ValueProp5.H3[language]}</H3>
              <P>{Texts.ValuePropsSection.ValueProp5.P[language]}</P>
            </ValueProp>
          </ValuePropRow>
        </BodyContentContainer>
      </ValuePropsSection>

      <QuotesSection> 
        <BodyContentContainer>
          <H2>{Texts.QuotesSection.H2[language]}</H2>
          <QuotesContainer>
            <Quote>
              <QuoteCompanyLogo src={NividiaLogo} alt={'Nividia Logo'}/>
              <H3>{Texts.QuotesSection.Quote1.H3[language]}</H3>
              <P>{Texts.QuotesSection.Quote1.P[language]}</P>
            </Quote>
            <Quote>
              <QuoteCompanyLogo src={EpicGamesLogo} alt={'Epic Games Logo'}/>
              <H3>{Texts.QuotesSection.Quote2.H3[language]}</H3>
              <P>{Texts.QuotesSection.Quote2.P[language]}</P>
            </Quote>
            <Quote>
              <QuoteCompanyLogo src={MedteqLogo} alt={'Medteq Logo'}/>
              <H3>{Texts.QuotesSection.Quote3.H3[language]}</H3>
              <P>{Texts.QuotesSection.Quote3.P[language]}</P>
            </Quote>
          </QuotesContainer>


        </BodyContentContainer>

      </QuotesSection>

      <PartnershipsSection>
        <BodyContentContainer>

          <P>{Texts.PartnershipsSection.P[language]}<span>{Texts.PartnershipsSection.P.span[language]}</span></P>
          <PartnershipLogosContainer>

            <PartnerShip>
              <PartnerLogo src={VirteasyLogo} alt={'Virteasy Logo'} />

            </PartnerShip>
            <PartnerShip>
              <PartnerLogo src={IMSTKLogo} alt={'IMSTK Logo'} />
            </PartnerShip>
            <PartnerShip>
              <PartnerLogo src={FundamentalLogo} alt={'Fundamental Logo'} />
            </PartnerShip>
            <PartnerShip>
              <PartnerLogo src={ToiaLogo} alt={'Toia Logo'} />
            </PartnerShip>
            <PartnerShip>
              <PartnerLogo src={AutodeskLogo} alt={'Autodesk Logo'} />
            </PartnerShip>
          </PartnershipLogosContainer>
        </BodyContentContainer>
      </PartnershipsSection>

      <OurProductTitleSection>
        <H2>{Texts.OurProductTitleSection[language]}</H2>
      </OurProductTitleSection>

      <CarouselSection id="carousel" onMouseDown={(e)=> {handleMouseDown(e)}} onMouseMove={(e) => handleScroll(e)} onMouseUp={(e)=> {handleMouseUp(e)}}>
        <BodyContentContainer>
        <CourselInteriorContainer >
          <CarouselCard>
            <CarouselCardContent>
              <H3>{Texts.CarouselSection.CarouselCardContent1.H3[language]}</H3>
              <P>{Texts.CarouselSection.CarouselCardContent1.P[language]}</P>
              <button className={"button-style-1"}>{Texts.CarouselSection.CarouselCardContent1.button[language]}</button>
            </CarouselCardContent>
            <CarouselIMG src={controllers} alt="inverse3 render"/>
          </CarouselCard>
          <CarouselCard>
          <CarouselCardContent>
          <H3>{Texts.CarouselSection.CarouselCardContent3.H3[language]}</H3>
          <P>{Texts.CarouselSection.CarouselCardContent3.P[language]}</P>

            <button className={"button-disbled_style-1"}>{Texts.CarouselSection.CarouselCardContent2.button[language]}</button>
          </CarouselCardContent>
            <CarouselIMG src={toolsAndAccessories} alt=""/>
          </CarouselCard>
          <CarouselCard>
          <CarouselCardContent>
            <H3>{Texts.CarouselSection.CarouselCardContent2.H3[language]}</H3>
            <P>{Texts.CarouselSection.CarouselCardContent2.P[language]}</P>
            <button className={"button-style-1"}>{Texts.CarouselSection.CarouselCardContent3.button[language]}</button>
          </CarouselCardContent>
            <CarouselIMG src={store} alt=""/>
          </CarouselCard>
        </CourselInteriorContainer>

        </BodyContentContainer>

      </CarouselSection>

      <VideoGallery>
        <BodyContentContainer>

          <H2>{Texts.VideoGallery.H2[language]}</H2>
          <VideoContainer>
            <VideoCard>
              <VideoPlayer controls controlsList="nodownload">
                <source src={HapInAction} type="video/mp4"/>
                {/* <source src="movie.ogg" type="video/ogg"/> */}
                {Texts.VideoGallery.VideoCard1.warning[language]}
              </VideoPlayer>
              <H2> {Texts.VideoGallery.VideoCard1.H2[language]}</H2>
              <P>
                {Texts.VideoGallery.VideoCard1.P[language]}
              </P>
            </VideoCard>

            <VideoCard>
                <VideoPlayer controls controlsList="nodownload">
                  <source src={IdeaToProduction} type="video/mp4"/>
                  {/* <source src="movie.ogg" type="video/ogg"/> */}
                  {Texts.VideoGallery.VideoCard1.warning[language]}
              </VideoPlayer>
              <H2> {Texts.VideoGallery.VideoCard2.H2[language]}</H2>
              <P>
                {Texts.VideoGallery.VideoCard2.P[language]}
              </P>
            </VideoCard>

            <VideoCard>
                <VideoPlayer controls controlsList="nodownload">
                  <source src={ThenAndNow} type="video/mp4"/>
                  {/* <source src="movie.ogg" type="video/ogg"/> */}
                  {Texts.VideoGallery.VideoCard1.warning[language]}
              </VideoPlayer>
              <H2> 
                {Texts.VideoGallery.VideoCard3.H2[language]}
              </H2>
              <P>
                {Texts.VideoGallery.VideoCard3.P[language]}
              </P>
            </VideoCard>

          </VideoContainer>
          
        </BodyContentContainer>

      </VideoGallery>

      <ContactFormSection>
        <BodyContentContainer>
          <ContactFormRow>
            <FormSection>

              <H3></H3>
              <P></P>

              <Form>
                <H3>{Texts.ContactFormSection.H3[language]}</H3>
                <P>{Texts.ContactFormSection.P[language]}</P>
                <Input placeholder={Texts.ContactFormSection.Inputs.I1[language]}></Input>
                <Input placeholder={Texts.ContactFormSection.Inputs.I2[language]}></Input>
                <Input placeholder={Texts.ContactFormSection.Inputs.I3[language]}></Input>
                <button className={"button-style-1"}>{Texts.ContactFormSection.button[language]}</button>
              </Form>
          
            </FormSection>
            <ImgSection>
              <Inverse3Image src={I3Img} alt={'inverse3 Render'} />
            </ImgSection>
          </ContactFormRow>

        </BodyContentContainer>

      </ContactFormSection>
      <Footer/>
    </HomeWrapper>
  )
}


// Styled components ----------------------------------

const HomeWrapper = styled.div`
  width: 100%;
  /* height: 500%; */
  background-color: black;
  overscroll-behavior-x: none;

  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */

  && > :not(:first-child) { margin: 0; padding: 0; box-sizing: border-box; opacity: 10}
  && .button-style-1{
    color: white;
    border-radius: 25px;
    height: 50px;
    width: 144px;
    border: solid 3px white;
  }
  && .button-disbled_style-1{
    color: grey;
    border-radius: 25px;
    height: 50px;
    width: 144px;
    border: solid 3px grey;
    cursor: default;

  }

`

const BodyContentContainer = styled.div`

  height: 100%;
  width: 80%;
  max-width: 1200px;
  z-index: 100;
`

const H2 = styled.h2`
    color: white;
    font-size: 2vw;
    text-align: center;
`
const H3 = styled.h3`
    color: #C4ACE6;
    font-size: 2vw;
    text-align: center;
`
const P = styled.p`
    color: white;
    text-align: center;

    && span {
      color: #C4ACE6;
    }
`
const BannerVideoSection = styled.div`
  width: 100%;
  /* height: 100vh; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* object-fit: cover; */
  /* overflow-y: none; */
`

const VideoBackground = styled.video`
  position: relative;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 90%;
  z-index: 0;
`
const VidSecContentContainer = styled.div`
  position: absolute;
  color: white;
  top: 40vh;
  /* width: 100%; */
  /* background-color: red; */
  opacity: 90%;
  z-index: 1;
`
const VidSecMiddleFloatContainer = styled.div`
  width: 100%;
  height: 363px; 
  min-width: 526px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 0;
`
const HaplyLogoPng = styled.img`
  position: relative;
  left: -12px;
  height: 207px;
  width: 526px;

`
const Inverse3Section = styled.div`
  width: 100%;
  height: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Inverse3SecContentContainer = styled.div`
  /* position: absolute; */
  /* top: 0px;
  left: 0px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Inverse3SecMiddleFloatContainer = styled.div`
  /* width: 60%; */
  max-width: 600px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  && h2, && h3, && p, && button {
    margin-bottom: 30px;
   }
`

const I3LogoImg = styled.img`
  height: 20%;

`

const Inverse3BGImg = styled.div`
  position: absolute;
  width: 100vw;
  max-width: 1000px;
  height: 100%;
  background-image: url(${inverseBackgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 60%;
  z-index: 0;
`

const ValuePropsSection = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;


`

const ValuePropRow = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const ValueProp = styled.div`
/* background-color: red; */
  height: 100%;
  width: 200px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  && h3{
    font-size: 15px;
    margin-bottom: 10px;
  }

  && p{
    font-size: 12px;
    padding: 0 10%
  }
`

const ValuePropImage = styled.img`
  max-width: 76%;
  height: auto;  
  margin-bottom: 40px;

`

const QuotesSection = styled.div`
  margin-top: 100px;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 40px; */


  /* background-color: orange; */
`

const QuotesContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  /* background-color: green; */
  justify-content: space-around;
  align-items: center;
`

const Quote = styled.div`
  width: 22%;
  max-width: 30%;
  height: 60%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: red; */
  && img {
    margin-bottom: 20px;
  }
  && h3{
    font-size: 1.7vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;   }
`

const QuoteCompanyLogo = styled.img`
  height: 80%;
`

const PartnershipsSection = styled.div`
  width: 100%;
  height: 194px;
  display: flex;
  align-items: center;
  justify-content: center;

  && p{
    margin-top: 10px;
    margin-bottom: 15px;

  }
  
`
const PartnershipLogosContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PartnerShip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  width: 150px;
  height: 100%;

`

const PartnerLogo = styled.img`
  width: 95%;
  height: auto;
`


const OurProductTitleSection = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  && h2{
    font-size: 20px;
    margin-bottom: 40px;
  }
`

const CarouselSection = styled.div`

  width: 100%;
  height: 90vh;
  overflow-x: scroll;
  cursor: grab;


  && *{
    pointer-events: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

  }
  /* && img{
  } */

  &&::-webkit-scrollbar{
    display: none;
  }
  && button{
    pointer-events: all;
  }

`

const CourselInteriorContainer = styled.div`
  width: 300%;
  height: 100%;
  display: flex;
  justify-content: space-between;

`

const CarouselCard = styled.div`
  pointer-events: none;
  position: relative;
  width: 33%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: flex-end;

`
const CarouselCardContent = styled.div`
  position: absolute;
  width: 100%;
  height: 20%;
  z-index: 1;
  display: flex;
  
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  /* background-color: rgba(0, 0, 0, 0.2); */
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6979166666666667) 60%, rgba(255,255,255,0) 100%);



  && button {
    margin-bottom: 20px;
  }

  && p {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;

  }

  && * {
    margin-left: 200px;
  }
`

const CarouselIMG = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`

const VideoGallery = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;

  && h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
  }
`

const VideoContainer = styled.div`
  width: 100%;
  height: 300px;
  /* background-color: yellow; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */

`

const VideoCard = styled.div`

  max-width: 35%;
  height: 90%;
  background-color: black;
  text-align: left;


  && * {
    padding: 0 4%;

    text-align: left;
  }

  && h2{
    font-size: 18px;
  }
  && p{

  }
`

const VideoPlayer = styled.video`
  margin: 10px;

`

const ContactFormSection = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const ContactFormRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

`

const FormSection = styled.div`
  width: 50%;
  height: 100%;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

`

const ImgSection = styled.div`

  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */

`

const Form = styled.div`
  display: flex;
  width: 60%;
  height: 80%;

  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  && h3{
    font-size: 15px;
  }
`

const Input = styled.input`
  display: inline-block;
  text-align: center;
  width: 80%;
  height: 70px;

`
const Inverse3Image = styled.img`
  /* width: 40%; */
  height: 100%;
  object-fit: cover;

`



// const Footer = styled.div`
//   width: 100%;
//   height: 354px;
//   background-color: white;
// `



export default Home

export const Head = () => (
  <Seo title="Haply Robotics | technology touching lives" />
)
